import useSWR from 'swr'
import { fetcherRepoGroupers } from '../apiclient'
import type { RepoGrouper } from '../RepoGroupers'

export const useRepoGroupers = (
  listAll?: boolean,
  parent?: string,
  grouper?: string
) => {
  const { data, error } = useSWR(
    ['/Repo/ListRepoGroupers', listAll, parent, grouper],
    fetcherRepoGroupers
  )
  const repoGroupers = data?.data?.repoGroupers as RepoGrouper[]
  const returnObj = {
    repoGroupers,
    count: repoGroupers?.length,
    loading: !error && !data,
    error: error
  }
  return returnObj
}
