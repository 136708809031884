import { useEffect, useState } from 'react'
import Base from '../components/base'
import Button from '../components/Elements/Button'
import TextField from '../components/Elements/TextField'
import { instance } from '../@nanpos/plughub-react/apiclient'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formateReal } from '../utils/formatter'
import axios from 'axios'
import { GoChevronLeft } from 'react-icons/go'
import { useBookingCartContext } from '../@nanpos/plughub-react/BookingCartContext'

interface DataMemberships {
  category: string
  link: string
  membershipType: string
  plan: string
  program: string
  id: string
  availability: DataAvailabilities
}

interface DataAvailabilities {
  programId: number
  programName: string
  categoryId: number
  categoryName: string
  categorySlug: string
  memberships: number
  membershipsUnavailability: number
  ticketsLimit: number
  ticketsAvailability: number
  ticketsReserved: number
  ticketsConfirmed: number
  ticketTypes: number
}

interface Data {
  id: number
  displayName: string
  documentTypeCpf: string
  memberships: DataMemberships[]
}

export function Customers() {
  const { id } = useParams()
  const [member, setMember] = useState<DataMemberships[]>([])
  const [data, setData] = useState<Data | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const { addOwner } = useBookingCartContext()
  let navigate = useNavigate()

  // const [id, setId] = useState<any>()

  useEffect(() => {
    id && getData()
    return () => {
      setData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getData = async () => {
    setLoading(true)
    const auth = localStorage.getItem('user_cdigital_pdv') as string
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_HOST}/customers/` + id, //'/availabilities/?eventSlug=corinthians-x-botafogo-rj',
      headers: {
        Authorization: `Bearer ${JSON.parse(auth).access}`,
      },
    }

    //09670515416
    axios
      .request(config)
      .then((response) => {
        setData(response.data)
        // if (
        //   response.data.memberships?.find(
        //     (i: { availability: any }) => i.availability,
        //   )
        // ) {
        setMember(response.data.memberships)
      })
      .catch((error) => {
        setError('Cliente não encontrado.')
      })
      .finally(() => setLoading(false))
  }

  const onSelected = (id: string) => {
    if (data) {
      const { displayName, documentTypeCpf } = data
      addOwner(`${id}__${displayName}__${documentTypeCpf}`)
      navigate('/list')
    }
  }

  return (
    <Base>
      <div className="container">
        <div className="content">
          <div className="header">
            <Link to="/">
              <div className="icon">
                <GoChevronLeft size={34} />
              </div>
            </Link>
            <h2>
              {loading ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <div
                    className="skeleton"
                    style={{ height: 35, width: 300 }}
                  ></div>
                  <div
                    className="skeleton"
                    style={{ height: 25, width: 200 }}
                  ></div>
                </div>
              ) : (
                <>
                  {data?.displayName}
                  <span>/ {data?.documentTypeCpf}</span>
                </>
              )}
            </h2>
          </div>
          {data && (
            <table className="table">
              <thead>
                <tr>
                  <th>Programa</th>
                  <th>Categoria</th>
                  <th>Plano</th>
                  <th>Tipo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {member?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.program}</td>
                      <td>{item.category}</td>
                      <td>{item.plan}</td>
                      <td>{item.membershipType}</td>
                      <td>
                        {item?.availability?.programId &&
                          item.availability?.categoryId === 42 && (
                            <Button onClick={() => onSelected(item?.id)}>
                              Selecionar
                            </Button>
                          )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Base>
  )
}
